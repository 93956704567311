import React from "react"

export default function Content(props){
    return (
            <div className="content">
                <h1>{props.postTitle}</h1>
                <i className="card-date">{props.postDate}</i>
                <div dangerouslySetInnerHTML={{__html: props.pageContent}}></div>
            </div>
        )
}