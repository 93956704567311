//This is the template for a single blog post

import React from "react"
import { graphql } from "gatsby"
import PostLayout from "../components/blog/post-layout"

import 'bootstrap/dist/css/bootstrap.min.css'
import "../styles/style.scss"

export default function PostTemplate(data) {
    const pageData = data.data.allMarkdownRemark.edges[0].node
    if(pageData.frontmatter.featuredImage == null){
      return <PostLayout postTitle={pageData.frontmatter.title} postDate={pageData.frontmatter.date} pageContent={pageData.html} />
    } else {
      return <PostLayout postTitle={pageData.frontmatter.title} postDate={pageData.frontmatter.date} pageContent={pageData.html} 
        featuredImage={pageData.frontmatter.featuredImage.childImageSharp.fluid} />
    }
    
}

export const query = graphql`query($slug: String!){
  allMarkdownRemark (filter: {fields:{slug: {eq: $slug}}}){
    edges {
      node{
        fields {
          slug
        }
      html
      frontmatter {
        title
        position
        date
        featuredImage{
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
}`